import { AccountDataService as Account, PortfolioDataService as Portfolio } from '@/services'
import type { ApiResponse, PortfolioType, PortfoliosType } from '@/types/index'
import Bugsnag from '@bugsnag/js'
import _, { pick } from 'lodash'
import { defineStore } from 'pinia'
import type { PortfolioPayloadType } from '../../types/index'
import { ActionTypes } from '../action-types'
import { useErrorStore } from '../errorStore'
import { state as initialState } from '../state'
const state = pick(initialState,  [
  'portfolio',
  'portfolios',
  'status',
  'statusText',
  'isRequestLoading',
  'user_interaction_portfolio',
  'isDeleted',
  'message',
])

export const usePortfoliosStore = defineStore('portfoliosStore', {
  state: () => ({ ...state }),
  getters: {
    getMyPortfolios({ portfolios }): PortfoliosType {
      return portfolios
    },
    getMyPortfolio({ portfolio }): PortfolioType {
      return portfolio
    },
    getCurrentPortfolioPayload({ portfolio }): PortfolioPayloadType[] {
      return portfolio.attributes.payload
    },
    getPortfolioPayloadByID({ portfolio }): (payload_id: string | number) => PortfolioPayloadType {
      return (payload_id: string | number): PortfolioPayloadType => {
        return  _.find(portfolio.attributes.payload, {id:payload_id})
      }
    },
  },
  actions: {
    updatePortfolioState({
      payload,
      payload_id,
    }: {
      payload: PortfolioPayloadType
      payload_id: number
    }) {
      const currentPayload = this.getPortfolioPayloadByID(payload_id)
      Object.assign(currentPayload, payload)
    },
    addPortfolioState({ payload }: { payload: PortfolioPayloadType }) {
      this.portfolio.attributes.payload.push(payload)
    },
    [ActionTypes.REMOVE_UPDATE_MULTI_IMAGE](preview_id: number) {
      const indexOfObject = this.portfolio.attributes.payload.findIndex((object: { id: number }) => {
        return object.id === preview_id
      })
      if (indexOfObject !== -1) {
        this.portfolio.attributes.payload[indexOfObject].image_to_delete = true
      }
    },
    [ActionTypes.UPDATE_PORTFOLIO_MULTI_IMAGE](payload: PortfolioPayloadType) {
      if (this.getPortfolioPayloadByID(payload.id)) {
        this.updatePortfolioState({ payload: payload, payload_id: payload.id })
      }
    },

    async [ActionTypes.DELETE_AND_UPLOAD_VARIANT]({ portfolio_id }: { portfolio_id: string }) {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        if (!portfolio_id) return
        const portfolio = Portfolio.deleteAndUploadVariant(portfolio_id)
        resolve(portfolio)
      })


      try {
          const {
            status
          } = await promise

          if (status === "204") {

            console.log("DeleteAndUploadVariantJob started")
          }
        } catch (error) {
          if (import.meta.env.NODE_ENV=== 'production') {
            Bugsnag.notify(error)
          }
        }
    },

    async [ActionTypes.GET_PORTFOLIO_BY_ID]({ portfolio_id }: { portfolio_id: string }) {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        if (!portfolio_id) return
        const portfolio = Portfolio.getPortfolioByID(portfolio_id)
        resolve(portfolio)
      })

      try {
        const {
          data: { data: portfolio },
          status,
          statusText,
        } = await promise
        this.portfolio = portfolio
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.GET_PORTFOLIOS]() {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolios = Portfolio.getPortfolios()

        resolve(portfolios)
      })

      try {
        const {
          data: { data: portfolios },
          status,
          statusText,
        } = await promise
        this.portfolios = portfolios
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.CREATE_PORTFOLIO_STYLE]({ style_data }: { style_data: FormData }) {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolios = Account.createPortfolio(style_data)

        resolve(portfolios)
      })

      try {
        const { data, status, statusText } = await promise
        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.UPDATE_PORTFOLIO_STYLE]({
      style_data,
      portfolio_id,
    }: {
      style_data: FormData
      access_token: string
      portfolio_id: string
    }) {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolio = Account.updatePortfolio(style_data, portfolio_id)

        resolve(portfolio)
      })
      try {
        const {
          data,
          status,
          statusText,
        } = await promise

        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.DELETE_PORTFOLIO]({ style_id }: { style_id: string }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolio = Account.deletePortfolio(style_id)

        resolve(portfolio)
      })

      try {
        const { data, status, statusText } = await promise

        this.GET_MY_PORTFOLIOS()
        this.message = data.message
        this.isDeleted = true
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.GET_MY_PORTFOLIOS]() {
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolios = Account.getMyPortfolios()

        resolve(portfolios)
      })

      try {
        const {
          data: { data: portfolios },
          status,
          statusText,
        } = await promise

        this.portfolios = portfolios
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
  },
  persist: false,
})
